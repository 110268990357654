import {useState, useEffect, useContext, useRef} from "react"
import {Link, useNavigate, useLocation, Outlet} from 'react-router-dom';
import {getUser, getAccounts, viewUser, viewAccounts} from './libs/user';
import {post} from './libs/api';
import {showAmount} from "./libs/util";
import useIndicesStore from "./../src/stores/useIndicesStore"
import {DisplayAmount} from './components/common/display';
import UserContext from "./libs/context";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {showDateTime} from "./libs/util";
import useInterval from "./libs/useInterval";
import SockJsClient from 'react-stomp';

import {Notices} from './components/common/msg';

const WS_URL = process.env.REACT_APP_API_URL + '/ws';

const Portal = ({children}) => {
    const [isLogin, setIsLogin] = useState(false);
    const [isManageAgent, setIsManageAgent] = useState(false);
    const [manageUser, setManageUser] = useState(null);
    const [user, setUser] = useState(viewUser());
    const [accounts, setAccounts] = useState(viewAccounts());
    const [menu, setMenu] = useState(null);
    const { indices, setIndices } = useIndicesStore();

    const [time, setTime] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();
    const {currentUser, authStatus, logout, setNotification} = useContext(UserContext);
    const [notices, setNotices] = useState([]);

    const getCurrentUser = async() => {
        const data = await post('/user/get');
        if(data?.status == 200){
            setUser(data.data);
        }
        //ADD MAINTENANCE NOTICE HERE
        // setNotices(['Maintenance Notice: Our investment platform will be undergoing partial maintenance from 21st December 2023, 9:00 AM UTC to ' +
        // '21st December 2023, 9:30 AM UTC. During this time, you will not be able to access the investment function and products. ' +
        // 'We apologise for the inconvenience.']);
    }

    useEffect(() => {
        getCurrentUser();
        checkMtnMsg();
    }, []);

    useEffect(() => {
        if ((user!=null) && (user.role == 'agent')) {
            setIsManageAgent(true);
            if (user.manage_user !=null)
                setManageUser(user.manage_user);
        } else {
            setIsManageAgent(false);
        }
    }, [user]);


    const handleLogout = () => {
        logout();
        navigate('/login');
    }

    useEffect(() => {
        if (authStatus && authStatus != 'VERIFIED') {
            navigate('/login');
            return;
        }
        setIsLogin(true);
    }, [authStatus]);

    // hide USDT
    const filterAccounts = (list) => {
        let filtered = [];
        for (const acc of list) {
            if(acc.currency == "USD"){
                acc.currency = "US$"
              }
            filtered.push(acc);
        }
        return filtered;
    }

    useEffect(() => {
        updateAccount();
    }, [isLogin])

    useEffect(() => {
        const path = location.pathname;
        if (path == '/portal' || path == '/portal/') {
            setMenu('plans');
        } else if (path.startsWith('/portal/profile')) {
            setMenu('profile');
        } else if (path.startsWith('/portal/wallet')) {
            setMenu('wallet');
        } else if (path.startsWith('/portal/transactions')) {
            setMenu('transactions');
        } else if (path.startsWith('/portal/plans')) {
            setMenu('plans');
        } else if (path.startsWith('/portal/investment')) {
            setMenu('investment');
        } else {
            setMenu(null);
        }
    }, [location])



    const updateTime = async () => {
        const date = new Date();
        const utcDate = new Date(date.getUTCFullYear(), date.getUTCMonth(),
            date.getUTCDate(), date.getUTCHours(),
            date.getUTCMinutes(), date.getUTCSeconds());
        setTime(utcDate);
    }

    const updateAccount = async () => {
        if (isLogin) {
            getAccounts().then((data) => setAccounts(filterAccounts(data).reverse()));
        }
    }

    const onConnected = () => {
        // websocket connected
    }

    const onDisconnected = () => {
        // websocket disconnected
    }

    const onMessageReceived = (msg) => {
        setNotification(msg);
        alert(msg.message);
    }

    const checkMtnMsg = async() => {
        const data = await post('/auth/mtn/msg');
        if(data?.data?.mtnmsg){
            setNotices([data?.data?.mtnmsg]);
        } else {
            setNotices([]);
        }
    }

    useEffect(() => {
        const loadIndex = async () => {
          const data = await post('/investment/index/list');
          if (data.status === 200) {
            setIndices(data.data); 
          }
        };
    
        loadIndex();
        const intervalId = setInterval(loadIndex, 2000);
    
        return () => clearInterval(intervalId);
      }, [setIndices]);

    useInterval(updateTime, 1000);
    useInterval(updateAccount, 3000);

    useInterval(() => {
        checkMtnMsg();
    }, 5000); //5sec

    return (
        <>
            {isLogin && (
                <div className="nk-body npc-cryptlite has-sidebar has-sidebar-fat">
                    <SockJsClient
                        url={WS_URL}
                        topics={['/topic/user-' + currentUser?.id]}
                        onConnect={onConnected}
                        onDisconnect={onDisconnected}
                        onMessage={msg => onMessageReceived(msg)}
                        debug={false}
                    />
                    <div className="nk-app-root">
                        <div className="nk-main">
                            <div className="nk-sidebar nk-sidebar-fat nk-sidebar-fixed is-dark"
                                 data-content="sidebarMenu">
                                <div className="nk-sidebar-element nk-sidebar-head">
                                    <div className="nk-sidebar-brand">
                                        <a className="logo-link nk-sidebar-logo" href="/portal">
                                            <img className="logo-img logo-light logo-img-md" src="/images/logo-white.svg"
                                                 srcSet="/images/logo-white.svg" alt="Penguin Securities"/>
                                            <img className="logo-img logo-dark logo-img-md" src="/images/logo-red.svg"
                                                 srcSet="/images/logo-red.svg" alt="Penguin Securities"/>
                                        </a>
                                    </div>
                                    <div className="nk-menu-trigger mr-n2">
                                        <a href="#" className="nk-nav-toggle nk-quick-nav-icon d-xl-none"
                                           data-target="sidebarMenu"><em className="icon ni ni-arrow-left"></em></a>
                                    </div>
                                </div>

                                <div className="nk-sidebar-element">
                                    <div className="nk-sidebar-body" data-simplebar>
                                        <div className="nk-sidebar-content">
                                            <div className="nk-sidebar-widget d-none d-xl-block">


                                                {(manageUser &&
                                                <div className="user-account-label">
                                                    <span className="btn-outline-warning font-weight-bold ">Managing Client: {manageUser.name} </span>
                                                </div>
                                                )}
                                                <div className="user-account-info between-center">
                                                    <div className="user-account-main">
                                                        <Link to="/portal/wallet">
                                                            <h6 className="overline-title-alt">Wallet Balances</h6>
                                                            {accounts.map((x, i) => (
                                                                <div className="user-balance" key={i}>
                                                                    {showAmount(x.main_balance, x.currency, true)} <small
                                                                    className="currency">{x.currency}</small>
                                                                </div>
                                                            ))}
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="user-account-actions">
                                                    <ul className="g-3">
                                                        <li><Link to="/portal/wallet/whitelist/add"
                                                                  className="btn btn-gray"><span>Whitelist</span></Link>
                                                        </li>
                                                        <li><Link to="/portal/deposit"
                                                                  className="btn btn-primary"><span>Deposit</span></Link>
                                                        </li>
                                                        <li><Link to="/portal/withdraw"
                                                                  className="btn btn-gray"><span>Withdraw</span></Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="nk-sidebar-widget nk-sidebar-widget-full d-xl-none pt-0">
                                                <div className="nk-profile-content toggle-expand-content"
                                                     data-content="sidebarProfile">
                                                    <div className="user-account-info between-center">
                                                    </div>
                                                    <ul className="user-account-data">
                                                        <li>
                                                            <div className="user-account-label">
                                                                <span className="sub-text">Profits (7d)</span>
                                                            </div>
                                                            <div className="user-account-value">
                                                                <div className="user-account-value">
                                                                    <span className="lead-text"> 0.00 <span
                                                                        className="currency">USD</span></span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>

                                                    <ul className="link-list">
                                                        <li><Link to="/portal/profile"><em
                                                            className="icon ni ni-user-alt"></em><span>View Profile</span></Link>
                                                        </li>
                                                        <li><Link to="/portal/profile"><em
                                                            className="icon ni ni-setting-alt"></em><span>Account Setting</span></Link>
                                                        </li>
                                                        <li><Link to="/portal/profile/activity"><em
                                                            className="icon ni ni-activity-alt"></em><span>Login Activity</span></Link>
                                                        </li>
                                                    </ul>

                                                    {isManageAgent && (
                                                    <ul className="link-list">
                                                        <li><Link to="/agent"><em
                                                            className="icon ni ni-activity-alt"></em><span>Agent Dashboard</span></Link>
                                                        </li>
                                                    </ul>
                                                        )}
                                                    <ul className="link-list">
                                                        <li><a href="#" onClick={handleLogout}><em
                                                            className="icon ni ni-signout"></em><span>Sign out</span></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="nk-sidebar-menu">
                                                <ul className="nk-menu">

                                                    <li className="nk-menu-heading">
                                                        <h6 className="overline-title">Menu</h6>
                                                    </li>


                                                    <li className={menu == 'plans' ? "nk-menu-item active" : 'nk-menu-item'}>
                                                        <Link to="/portal/planscc" className="nk-menu-link">
                                                            <span className="nk-menu-icon"><FontAwesomeIcon
                                                                icon="box-stack" className="icon"/></span>
                                                            <span className="nk-menu-text">Our Products</span>
                                                        </Link>

                                                        <ul className="nk-menu-sub">

                                                            <li className="nk-menu-item">
                                                                <Link to="/portal/planscc" className="nk-menu-link">
                                                                    - <span
                                                                    className="nk-menu-text">Penguin Secure </span>
                                                                </Link>
                                                            </li>
                                                            {process.env.REACT_APP_IS_TEST == 'true' && (
                                                            <li className="nk-menu-item">
                                                                <Link to="/portal/plansccplus" className="nk-menu-link">
                                                                    - <span className="nk-menu-text">Penguin Secure +</span>
                                                                </Link>
                                                            </li>)}

                                                            {process.env.REACT_APP_IS_TEST == 'true' && (
                                                            <li className="nk-menu-item">
                                                                <Link to="/portal/plans" className="nk-menu-link">
                                                                    - <span className="nk-menu-text">High Yield Dual Investment</span>
                                                                </Link>
                                                            </li>)}

                                                        </ul>

                                                    </li>

                                                    <li className={menu == 'investment' ? "nk-menu-item active" : 'nk-menu-item'}>
                                                        <Link to="/portal/investment" className="nk-menu-link">
                                                            <span className="nk-menu-icon"><FontAwesomeIcon icon="vault"
                                                                                                            className="icon"/></span>
                                                            <span className="nk-menu-text">Your Investments</span>
                                                        </Link>
                                                    </li>

                                                    <li className={menu == 'wallet' ? "nk-menu-item active" : 'nk-menu-item'}>
                                                        <Link to="/portal/wallet" className="nk-menu-link">
                                                            <span className="nk-menu-icon"><FontAwesomeIcon
                                                                icon="ArrowLeftRight" className="icon"/></span>
                                                            <span className="nk-menu-text">Wallet Management</span>
                                                        </Link>
                                                    </li>

                                                    {/*<li className={menu == 'wallet' ? "nk-menu-item active" : 'nk-menu-item'}>*/}
                                                    {/*    <Link to="/portal/faq" className="nk-menu-link">*/}
                                                    {/*        <span className="nk-menu-icon"><FontAwesomeIcon*/}
                                                    {/*            icon="ArrowLeftRight" className="icon"/></span>*/}
                                                    {/*        <span className="nk-menu-text">FAQs</span>*/}
                                                    {/*    </Link>*/}
                                                    {/*</li>*/}

                                                </ul>
                                            </div>

                                            <div className="nk-sidebar-footer">
                                            <ul className="nk-menu nk-menu-footer">
                                                    <li className="nk-menu-item">
                                                        {/* <a href="/page/contact-us" className="nk-menu-link">
                                      <span className="nk-menu-icon"><em className="icon ni ni-question"></em></span>
                                      <span className="nk-menu-text">Contact</span>
                                  </a> */}
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="nk-wrap">
                                <div className="nk-header nk-header-fluid nk-header-fixed is-light">
                                    <div className="container-fluid">
                                        <div className="nk-header-wrap">
                                            <div className="nk-menu-trigger d-xl-none ml-n1">
                                                <a href="#" className="nk-nav-toggle nk-quick-nav-icon"
                                                   data-target="sidebarMenu"><em className="icon ni ni-menu"></em></a>
                                            </div>

                                            <div className="nk-header-brand d-xl-none">
                                                <a className="logo-link" href="/portal">
                                                    <img className="logo-img logo-light" src="/images/logo-white.svg"
                                                         srcSet="/images/logo-white.svg" alt="Penguin Securities"/>
                                                    <img className="logo-img logo-dark" src="/images/logo-red.svg"
                                                         srcSet="/images/logo-red.svg" alt="Penguin Securities"/>
                                                </a>
                                            </div>


                                            {process.env.REACT_APP_IS_TEST == 'true' && (
                                            <div className="staging-top-bar">
                                                <p>This is staging</p>
                                            </div>
                                            )}

                                            <div
                                                className="nk-header-marque d-none d-md-block pr-md-3 pr-lg-4 pl-md-4 pl-xl-0 flex-grow-0 overflow-hidden">

                                                {/* <div className="nk-marque" data-duration="12000"> */}
                                                <ul className="rate-list rate-plain">
                                                    {indices.map((x, k) => (
                                                        <li className="rate-item" key={k}>
                                                            <div className="rate-title">{x.name} =</div>
                                                            <div className="rate-amount">{showAmount(x.price)}</div>
                                                        </li>
                                                    ))}
                                                </ul>
                                                {/* </div> */}

                                                <div class="sub-text mt-sm-1"><i>* Indicative prices for reference purposes only</i> </div>
                                            </div>

                                            <div className="nk-header-tools">
                                                <ul className="nk-quick-nav">
                                                    <li className="dropdown user-dropdown">
                                                        <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                                            <div className="user-toggle">
                                                                <div className="user-avatar sm">
                                                                    <em className="icon ni ni-user-alt"></em>
                                                                </div>
                                                                <div className="user-info d-none d-md-block">
                                                                    <div
                                                                        className="user-status user-status-verified">Verified
                                                                    </div>
                                                                    <div
                                                                        className="user-name dropdown-indicator">{currentUser?.name}<br/>
                                                                        {time && (showDateTime(time))}</div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                        <div
                                                            className="dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1">
                                                            <div className="dropdown-inner">
                                                                <ul className="link-list">
                                                                    <li><Link to="/portal/profile"><em
                                                                        className="icon ni ni-user-alt"></em><span>View Profile</span></Link>
                                                                    </li>
                                                                    <li><Link to="/portal/profile/security"><em
                                                                        className="icon ni ni-setting-alt"></em><span>Security Setting</span></Link>
                                                                    </li>
                                                                    <li><Link to="/portal/profile/activity"><em
                                                                        className="icon ni ni-activity-alt"></em><span>Login Activity</span></Link>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                            {isManageAgent && (

                                                                <div className="dropdown-inner">
                                                                    <ul className="link-list">
                                                                        <li><Link to="/agent"><em
                                                                            className="icon ni ni-user"></em><span>Agent Dashboard</span></Link>
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                            )}

                                                            <div className="dropdown-inner">
                                                                <ul className="link-list">
                                                                    <li>
                                                                        <a href onClick={handleLogout}>
                                                                            <em className="icon ni ni-signout"></em><span>Sign out</span></a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="nk-content nk-content-fluid">
                                    <div className="container-xl wide-lg">
                                        {notices?.length > 0 && (
                                            <Notices msgs={notices}/>
                                        )}

                                        <div className="nk-content-body">

                                            <Outlet/>
                                        </div>
                                    </div>
                                </div>

                                <div className="nk-footer">
                                    <div className="container-fluid">
                                        <div className="nk-footer-wrap">
                                            <div className="nk-footer-copyright">&copy; Penguin Securities All Rights Reserved.
                                            </div>
                                            <div className="nk-footer-links">
                                                <ul className="nav nav-sm">
                                                    <li className="nav-item">
                                                        <a className="nav-link" target={"_blank"}
                                                           href="https://client.penguinsecurities.sg/privacy-policy/">Legal Notices</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default Portal;